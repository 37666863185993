import React ,{ Component }from "react";
import { FiCast , FiCircle, FiLayers , FiUsers , FiMonitor, FiAperture, FiCheck,FiPlay } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCircle />,
        title: 'Adult Player Membership',
        description: '€150.00 GAA and LGFA. Click here to Join'
    },
    {
        icon: <FiLayers />,
        title: 'Student Membership',
        description: '€100.00 - GAA and LGFA. Click here to Join'
    },
    {
        icon: <FiAperture />,
        title: 'Juvenille Membership',
        description: '€80.00 GAA and LGFA. Click here to Join'
    },
    { 
        icon: <FiCheck />,
        title: 'Nursery Player',
        description: '€50.00 Nursery Player. Click here to Join'
    },
    {
        icon: <FiPlay />,
        title: 'Mothers & Others',
        description: '€50.00 LGFA Social. Click here to Join'
    },
    { 
        icon: <FiUsers />,
        title: 'Children’s Package (up to 4 juvenile children)',
        description: '€200.00 GAA and LGFA.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="https://member.clubforce.com/memberships_cart_m.asp?LL_ID=2909&clb=1">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
