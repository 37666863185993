import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Founded",
        tab2 = "Membership",
        tab3 = "Facilities & Grounds"
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                In 1980 from the merger of Kilbeg (founded in 1939) 
                                                </li>
                                                <li>
                                                and Carlanstown (St. Patricks, founded in 1926).
                                                </li>
                                                <li>
                                                <br />
                                                </li>
                                   
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://member.clubforce.com/memberships_cart_m.asp?LL_ID=2909&clb=1">Join St Michaels <span>- Click Here</span></a> 
                                               </li>
                                               <li>
                                                <br />
                                                </li>
                                                <li>
                                                <br />
                                                </li>
                                            </ul>
                                            
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                               Clubhouse and Pitch off N52, and 18 acres (The Deerpark) on Kilbeg Road.
                                               </li>
                                               <li>
                                                   <br />
                                               </li>
                                               <li>
                                               <br />
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">BSc In CSE<span> - ABC University, Los Angeles, CA</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Diploma in Computer Science<span> - Gamma Technical Institute</span></a> 2009
                                               </li>
                                               <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;